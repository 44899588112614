import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import emailjs from "emailjs-com";
import Footer from "./Footer";

const HomePage = () => {
  const enquiryFormRef = useRef<HTMLDivElement>(null);

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const scrollToEnquiryForm = () => {
    if (enquiryFormRef.current) {
      enquiryFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    console.log("Current target:", event.currentTarget);

    const formData = new FormData(event.currentTarget);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");
    const phone = formData.get("phone");

    const templateParams = {
      name: name,
      email: email,
      message: message,
      phone: phone,
    };

    emailjs
      .send(
        "service_ban6o18",
        "template_hdn5xnr",
        templateParams,
        "E91OotIorBbdQESyu"
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          setFeedbackMessage(
            "Your message has been sent. We will get back to you soon."
          );

          setFormSubmitted(true); // Set formSubmitted to true after successful submission
        },
        (error) => {
          console.error("Error sending email:", error);
        }
      );
  };

  const handleLegalAreaClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="home">
      <nav className="home-header">
        <Navigation />
      </nav>
      <div className="banner">
        <picture>
          <source
            srcSet="https://janssen-law.s3.us-east-2.amazonaws.com/JanssenBannerMobile.png"
            media="(max-width: 768px)"
          />
          <img
            src="https://janssen-law.s3.us-east-2.amazonaws.com/JanssenBanner.png"
            alt="Janssen Law Banner"
          />
        </picture>
      </div>

      <main className="home-main">
        <section className="services">
          <h1>Areas of Law We Practice</h1>
          <div className="law-categories">
            <div className="card business" onClick={handleLegalAreaClick}>
              <h3>Business Law</h3>
            </div>
            <div className="card real-estate" onClick={handleLegalAreaClick}>
              <h3>Real Estate Law</h3>
            </div>
            <div className="card immigration" onClick={handleLegalAreaClick}>
              <h3>Immigration Law</h3>
            </div>
            <div className="card wills-estates" onClick={handleLegalAreaClick}>
              <h3>Wills & Estates</h3>
            </div>
          </div>
          <div onClick={scrollToEnquiryForm} className="btn-contact">
            Need a No-Nonsense Lawyer? <FaArrowRight />
          </div>
        </section>
        {/* <section className="hero">
          <h1 className="h1-container">
            Business, Real Estate, Immigration, Wills &amp; Estates Law Firm in
            Toronto
          </h1>

          <Link to="/contact" className="btn-contact">
            Need a No-Nonsense Lawyer? <FaArrowRight />
          </Link>
        </section> */}
        <section className="about section-dark">
          <h2>About Janssen Law</h2>
          <div className="outline">
            <p>No-Nonsense, Results-Oriented Law Firm.</p>

            <p>Charlotte Janssen oversees every case.</p>
          </div>
          <div onClick={scrollToEnquiryForm} className="btn-more">
            Get Quote
          </div>
        </section>

        <section className="resume">
          <div className="resume-container">
            <h1>Charlotte Janssen's Resume</h1>

            <div className="resume-section">
              <h2>Education</h2>
              <ul>
                <li>University of Toronto, Bachelor of Law, J.D. (1986)</li>
                <li>Carleton University, Master of Arts (Economics)</li>
                <li>University of Manitoba, Bachelor of Arts, Honours</li>
              </ul>
            </div>

            <div className="resume-section">
              <h2>Areas of Practice</h2>
              <ul className="practice-areas">
                <li>Mergers and Acquisitions</li>
                <li>Corporate/Commercial</li>
                <li>Real Estate</li>
                <li>International Business</li>
                <li>Pre-Immigration and Estate/Business Planning</li>
                <li>Immigration</li>
                <li>Banking</li>
                <li>Finance</li>
                <li>Securities</li>
                <li>Insolvency</li>
                <li>Elder Law</li>
                <li>Wills, Estates and Trusts</li>
                <li>Trademark Representative for Service</li>
              </ul>
            </div>

            <div className="resume-section">
              <h2>Bar Admission/Year of Call</h2>
              <p>1988, Law Society of Upper Canada (Ontario)</p>
            </div>

            <div className="resume-section">
              <h2>About Me</h2>
              <p>
                Graduate of University of Manitoba (1982 BA Honours -
                Economics), Carleton University (1983 MA - Economics) and
                University of Toronto (1986 J.D. - Law) and member in good
                standing of the Law Society of Upper Canada since 1988.
                Charlotte has been practicing law in private practice for almost
                30 years. Currently the owner and manager of Janssen Law
                Professional Corporation, she advises business owners of
                Canadian and international businesses. Consistent with in-house
                counsel, she provides a vast array of legal advice in the areas
                of Corporate, Commercial, Finance, Immigration, Real Estate, and
                Wills, Estates, and Trusts to high net worth clients. Prior to
                establishing her own firm in 1996, she worked with Baker &
                McKenzie in Toronto (Canada), Sao Paolo (Brazil), and London
                (UK) and as a partner of the firm Baker & Janssen. She also
                serves as a director of numerous private companies and community
                organizations, including the Bloor Yorkville BIA, Yonge Bloor
                Bay Business Association, and the Foundation for the Abilities
                Centre.
              </p>
            </div>

            <div className="resume-section">
              <h2>Professional Membership and Positions</h2>
              <p>
                Member of the Law Society of Upper Canada, Canadian Bar
                Association, Past Member of Council, Past Chair, International
                Law Section, Past Member of Budget Committee, Member of the
                International Law, Immigration, Business, Real Estate, Elder
                Law, and Estates and Trusts Sections, Canadian Representative to
                The Hague Conference on International Law on Foreign Laws in
                Civil and Commercial Matters (2012), Ontario Bar Association,
                Past Member of Council, Past Chair- International Law Section.
                Directorships include Yonge Bloor Bay Business Association
                (President), Government Affairs and Small Business Committee
                (Chair), Bloor Yorkville Business Improvement Area (Member of
                the Board of Management, PUDD Committee Member), Director of
                Numerous Privately Owned Corporations.
              </p>
            </div>
          </div>
        </section>

        <section className="enquiry-form" ref={enquiryFormRef}>
          <div className="form-container">
            <h2>Enquiry Form</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone: (optional)</label>
                <input type="tel" id="phone" name="phone" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <button
                type="submit"
                className={`submit-button ${formSubmitted ? "sent" : ""}`}
                disabled={formSubmitted}
              >
                {formSubmitted ? (
                  <>
                    <FaCheck className="icon" /> Sent
                  </>
                ) : (
                  "Send Message"
                )}
              </button>
              {feedbackMessage && (
                <div className="feedback-message">{feedbackMessage}</div>
              )}
            </form>
          </div>
        </section>

        {/* <section className="testimonials">
          <h2>What Our Clients Say</h2>
          <blockquote>
            “Our clients are our #1 priority. We strive to provide each client
            with the best possible advice and results."
          </blockquote>
          <cite>- Charlotte M. Janssen</cite>
        </section> */}
      </main>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p>This part of the website is coming soon.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default HomePage;
