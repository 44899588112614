import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaPhone, FaEnvelope } from "react-icons/fa";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const scrollToEnquiryForm = () => {
    const enquiryFormSection = document.querySelector(".enquiry-form");
    if (enquiryFormSection) {
      enquiryFormSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    toggleMenu(); // Close the menu after clicking the link
  };

  const handlePracticeAreasClick = () => {
    setShowModal(true);
    toggleMenu(); // Close the menu after clicking the link
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <nav className="navigation">
      <div className="menu-icon" onClick={toggleMenu}>
        <div className={`hamburger ${isOpen ? "open" : ""}`}></div>
      </div>

      <ul className={`menu ${isOpen ? "open" : "closed"}`}>
        <div className="row-wrapper">
          <li>
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </li>

          <li>
            <div
              className="enquiry-nav-link"
              onClick={handlePracticeAreasClick}
            >
              Practice Areas
            </div>
          </li>
          <li>
            <div className="enquiry-nav-link" onClick={scrollToEnquiryForm}>
              Enquiry Form
            </div>
          </li>
        </div>

        <div className="row-wrapper">
          <li className="contact-item email">
            <a href="mailto:enquiry@janssen-law.com">
              <FaEnvelope />
              enquiry@janssen-law.com
            </a>
          </li>
          <li className="contact-item phone-number">
            <a href="tel:+14169291103">
              <FaPhone />
              (416) 929-1103
            </a>
          </li>
        </div>
      </ul>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p>This part of the website is coming soon.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
